import React from 'react'
import styled, { css } from 'styled-components'
import Fade from 'react-reveal/Fade'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveBlock } from './utils';

const Block = (props) => {
	const { layout, lastBlock, block_type, inAccordion, display, wrapContainer } = props;
	const BlockLayout = resolveBlock(layout);
	
	if (!BlockLayout || !display) return <></>

	return (
		<Wrapper
			className={`block_${layout}`}
			layout={layout}
			blockType={block_type}
			blockProps={props}
			styles={BlockLayout && BlockLayout.wrapper}
			lastBlock={lastBlock}
			inAccordion={inAccordion}
		>
			<Fade
				up
				distance={'30px'}
			>
				{BlockLayout && (
					wrapContainer ? 
						<Container>
							<BlockLayout {...props}/>
						</Container>					
					:	
						<BlockLayout {...props}/>
				)}
			</Fade>
		</Wrapper>
	)
}

const Wrapper = styled.div`
    width: 100%;
	/* margin-bottom: 60px; */
	
	/* Block specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}
`

const Container = styled.div`
	${container}
	${padding}
	margin: 0 auto;

	/* display: flex;
	flex-direction: column; */
`

export default Block
