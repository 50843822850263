import React, { useEffect, useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import 'intersection-observer' // polyfill for ie11
import { useMount } from 'react-use'

import Transition from '../../transition';

import 'swiper/css/swiper.css'

import { MetaTags, Header, Footer, Splash } from '../'
import { media, isClient } from '../../styles/utils';
import { headerContext } from '../Header/HeaderProvider'

const Layout = ({ children, meta, logoTheme, hideByLine, ...props }) => {
	const { setLogoTheme, setHideByLine } = useContext(headerContext);

    useMount(() => {
        if (!isClient) return
        window.scrollTo(0, 0)
    })
	
	useEffect(() => {
		setLogoTheme(logoTheme)
	}, [logoTheme])

	useEffect(() => {
		setHideByLine(hideByLine)
	}, [hideByLine])

	return (
		<>
			<MetaTags
				{...meta}
			/>

			<Wrapper>
				<Transition>
					{children}
				</Transition>
			</Wrapper>

			{!props.disableFooter && (
				<Footer/>
			)}
		</>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	box-sizing: border-box;
`

export default Layout
