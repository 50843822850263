import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useInView } from 'react-intersection-observer'

import { media } from '../../../styles/utils'
import { colours, container, bgIcon, padding, hoverState, bgImage, textOffset, linedText } from '../../../styles/global'
// import { subheading } from '../../../styles/type'
import { useMount } from 'react-use'

const BlockImage = (props) => {
	const { layout, layout_type } = props;
    const [images, setImages] = useState(false)
    
	useMount(() => {
		let images; 

		if (props.images) images = props.images
        if (props.image) images = [{ image: props.image }]

		setImages(images)
    })

    const renderText = (item) => {
        if (!item) return;

        return (
            <ImageWrapper>
                <TextWrapper
                    typeStyle={item.type_style}
                >
                    {item.type_style == 'small' && item.heading && (
                        <Heading
                            dangerouslySetInnerHTML={{__html: item.heading}}
                        />
                    )}

                    {item.short_text && (
                        <Description
                            dangerouslySetInnerHTML={{__html: item.short_text}}
                        />
                    )}
                </TextWrapper>
            </ImageWrapper>
        )
    }
	
	const renderImages = () => {
        if (!images) return;
        
		return images.map((item, i) => {
            if (item.image_or_text == 'text') return renderText(item)
            const portrait = props.layout ==  ('double_portrait_images' || 'landscape_portrait_images') && item?.image?.height > item?.image?.width;
            
            return (
                <ImageWrapper 
                    key={item?.image?.sizes?.medium2 || i}
                >
                    <Image 
                        src={item?.image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    as={portrait && 'img'}
                                    portrait={portrait}
                                    src={src} 
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image> 
                </ImageWrapper>
            )
        })
    }
    
	return (
        <Wrapper
            layout={layout}
            layoutType={layout_type}
        >
			{renderImages()}
		</Wrapper>
	)
}


// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const ImageWrapper = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.src});
    ${bgImage};
    transition: opacity 0.45s ease;
    height: 100px;
    width: 100%;
    background-color: ${colours.lightGray};

    /* Portrait */

    ${props => {
        if (props.portrait) return css`
            height: 100%;
            padding-bottom: 0 !important;
        `
    }}
`

const Wrapper = styled.div`
     margin-bottom: 110px;

    ${media.phone`
        margin-bottom: 60px;
    `}

    ${BGImage} {
        padding-bottom: 57.89%
    }

	${props => {
        if (props.layout == 'double_portrait_images' || 'landscape_portrait_images') return css`
            display: flex;
            justify-content: space-between;

            ${media.phone`
                display: block;
            `}

            ${ImageWrapper} {
                flex: 1;
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }

                ${media.phone`
                    margin-right: 0;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                `}
            }
        `

        if (props.layout == 'landscape_images_x4') return css`
            display: flex;
			flex-flow: row wrap;
			width: calc(100% + 40px);

			${ImageWrapper} {
				flex-basis: calc(25% - 40px);
				margin-right: 40px;

                ${media.phone`
                    flex-basis: 100%;
                    margin-right: 20px;
                    margin-bottom: 20px;
                `}
			}

            ${media.phone`
                width: calc(100% + 20px);
            `}
		`
        
        if (props.layout == 'landscape_image_grid') return css`
            display: flex;
			flex-flow: row wrap;
			width: calc(100% + 40px);
            /* margin-bottom: 120px; */

			${ImageWrapper} {
				flex-basis: calc(50% - 40px);
                margin-right: 40px;
                margin-bottom: 40px;

                ${media.phone`
                    flex-basis: 100%;
                    margin-right: 20px;
                    margin-bottom: 20px;
                `}
			}

            ${media.phone`
                width: calc(100% + 20px);
                /* margin-bottom: 0; */
            `}
		`
	}}

    /* Layout Types */

    ${props => {
        if (props.layoutType == 'q3_q1') return css`
            ${ImageWrapper} {
                &:first-child {
                    flex-basis: calc(75% - 40px);                    
                }

                &:last-child {
                    flex-basis: calc(25% - 40px);  

                    ${Description} {
                        margin-right: 0;
                    }
                }
            }
        `

        if (props.layoutType == 'q1_q3') return css`
            ${ImageWrapper} {
                &:last-child {
                    flex-basis: calc(75% - 40px);                    
                }

                &:first-child {
                    flex-basis: calc(25% - 40px);  

                    ${Description} {
                        margin-right: 0;
                    }
                }
            }
        `

        if (props.layoutType == 'portrait_left') return css`
            ${ImageWrapper} {
                &:last-child {
                    flex: 0 1 calc(50% - 40px); 
                    flex-basis: calc(50% - 40px);  
                }

                &:first-child {
                    flex: 0 1 calc(25% - 40px); 
                    flex-basis: calc(25% - 40px);  

                    ${BGImage} {
                        height: 100%;
                    }  

                    ${Description} {
                        margin-right: 0;
                    }
                }
            }
        `

        if (props.layoutType == 'portrait_right') return css`
            ${ImageWrapper} {
                &:first-child {
                    flex: 0 1 calc(50% - 40px); 
                    flex-basis: calc(50% - 40px);  
                }

                &:last-child {
                    flex: 0 1 calc(25% - 40px); 
                    flex-basis: calc(25% - 40px);

                    ${BGImage} {
                        height: 100%;
                    }  

                    ${Description} {
                        margin-right: 0;
                    }
                }
            }
        `
    }}
`

const TextWrapper = styled.div`
    ${props => {
        if (props.typeStyle == 'small') return css`
            ${Heading} {
                color: #000;
                margin-bottom: 6px;
                font-size: 20px;

                ${media.desktopSmall`
                    font-size: 16px;
                `}

                ${media.phone`
                    font-size: 18px;
                    line-height: 1;
                    margin-bottom: 4px;
                `}
            }

            ${Description} {
                margin-right: 50px;
                color: ${colours.midGray};
                ${textOffset};
            }
        `
    }}

    ${props => {
        if (props.typeStyle == 'large') return css`
            ${Description} {
                ${linedText};
                color: ${colours.darkRed};
            }
        `
    }}
`

BlockImage.wrapper = css`

`

export default BlockImage
