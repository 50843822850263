import React from 'react'
import { useSpring, animated } from 'react-spring'
import { useBreakpoint } from '../../styles/utils'

const Burger = ({ isNavOpen }) => {
	const closedY = 3
	const gap = 10
	const isPhone = useBreakpoint('phone')
	
	const openedTransformationConfig = {
		top: `translate(2, ${closedY}) rotate(0)`,
		center: `translate(2, ${closedY + gap}) rotate(0)`,
		bottom: `translate(2, ${closedY + gap * 2}) rotate(0)`,
	}

	const closedTransformationConfig = {
		top: `translate(7, ${closedY}) rotate(45)`,
		center: `translate(-50, ${closedY + gap}) rotate(0)`,
		bottom: `translate(5, 24) rotate(-45)`,
	}

	const { top, center, bottom, opacity } = useSpring({
		from: {
			opacity: 0,
		},
		opacity: 1,
		to: { ...(isNavOpen ? closedTransformationConfig : openedTransformationConfig), opacity: 1, },
		config: {
			mass: 1,
			tension: 700,
			friction: 50,
		},
	})

	return (
		<animated.svg
			width="30"
			height={isPhone ? '25' : '30'}
			viewBox="0 0 30 30"
			fill="currentColor"
			style={{ opacity }}
		>
			<animated.rect width="30" height="3" rx="0" transform={top} />
			<animated.rect width="30" height="3" rx="0" transform={center} />
			<animated.rect width="30" height="3" rx="0" transform={bottom} />
		</animated.svg>
	)
}

export default Burger
