import React, { useState, useEffect, useRef } from 'react'
import Swiper from 'react-id-swiper'
import ProgressiveImage from 'react-progressive-image'
import styled, { css } from 'styled-components'
import { useMedia } from 'react-use'

import {
    container,
    padding,
    linedText,
    bgImage,
    colours,
    type,
    textOffset,
    rolloverAnimation,
} from '../../styles/global'
import { ConditionalLink } from '..'
import { media, breakpoints } from '../../styles/utils'
import { leftFillNum } from '../../utils/utils'

import ArrowRight from '../../assets/images/arrow-right.inline.svg'
import dragCursor from '../../assets/images/drag-cursor.png'

const NewsSlider = ({data}) => {
    const [activeIndex, updateActiveIndex] = useState(0)
    const [swiper, updateSwiper] = useState(null)

    const onSlideChange = () => {
        updateActiveIndex(swiper ? swiper.realIndex : 0)
    }

    useEffect(() => {
        if (swiper) {
            swiper.on('slideChange', onSlideChange)
        }
    }, [swiper])

    function handleInteract() {
        setHasInteracted(true)
    }

    const [hasInteracted, setHasInteracted] = useState(false)

    const screenIsSmall = useMedia(`(max-width: ${breakpoints.phone}px)`)

    function renderCards() {
        return data.news_items.map((item) => (
            <ConditionalLink 
                key={item.slug} 
                to={item.acf.link}
                className={'card'}
            >
                <Subheading>{item.acf?.category}</Subheading>
                <Heading>{item.title}</Heading>

                <ParagraphIndent
                    dangerouslySetInnerHTML={{
                        __html: item.acf.text,
                    }}
                />

                <ImageWrapper>
                    <BGImage
                        image={item.acf.hero_image?.sizes?.medium2}
                    
                    />
                </ImageWrapper>
            </ConditionalLink>
        ))
    }

    function renderCounter() {
        return (
            <Counter>
                <ArrowRight />
                {leftFillNum(activeIndex + 1, 2)}
                <span>/{leftFillNum(data.news_items.length, 2)}</span>
            </Counter>
        )
    }

    const paramsSmall = {
        spaceBetween: 15,
        getSwiper: updateSwiper,
        slidesPerView: 'auto',
    }
    
    const paramsLarge = {
        grabCursor: true,
        spaceBetween: 0,
        getSwiper: updateSwiper,
        // freeMode: true,
        slidesPerView: 'auto',
    }

    const renderHoverCursors = () => {
		return (
			<HoverCursors className="hover-cursors">
				<Hover
					position={'left'}
					onClick={() => swiper && swiper.slidePrev()}
				>

				</Hover>
				<Hover
					position={'right'}
					onClick={() => swiper && swiper.slideNext()}
				>

				</Hover>
			</HoverCursors>
		)
    }
    

    return (
        <>
            {renderCounter()}

            <SliderWrapper
                // hasInteracted={hasInteracted}
                // onMouseDown={handleInteract}
                // onTouchStart={handleInteract}
            >
                {screenIsSmall ? (
                    <Swiper {...paramsSmall}>{renderCards()}</Swiper>
                ) : (
                    <Swiper {...paramsLarge}>{renderCards()}</Swiper>
                )}

                {renderHoverCursors()}
            </SliderWrapper>
        </>
    )
}

const Heading = styled.h2`
    font-size: 20px;
    color: #000;
    
    ${media.desktopSmall`
        font-size: 16px;
    `}
`

const Subheading = styled.h3`
    margin-bottom: 16px;
    color: ${colours.darkRed};
    ${rolloverAnimation};
    ${type.caption};    
    margin-right: auto;

    ${media.phone`
        margin-bottom: 10px;
    `}

    ${media.desktopSmall`
        font-size: 14px;
    `}
`


const ImageWrapper = styled.div`
    overflow: hidden;
    margin-top: auto;
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Counter = styled.div`
    display: none;
    align-items: center;
    ${type.body}

    img {
        margin-right: auto;
        width: 25px;
        height: 25px;
    }

    span {
        color: ${colours.darkRed};
    }

    svg {
        width: 25px;
        color: ${colours.darkRed};
        margin-right: auto;
    }

    ${media.tablet`
        display: flex;
        margin-bottom: 24px;
        ${padding}
    `}
`


const ParagraphIndent = styled.p`
    ${textOffset};
    color: ${colours.midGray};
    padding-right: 20px;
    margin-top: 0;
    margin-bottom: 32px;
    max-width: 520px;

    p {
        margin-top: 0;
        margin-bottom: 0;
    }
`

const SliderWrapper = styled.section`
    width: 100%;
    align-items: center;
    display: flex;
    padding-top: 50px;

    * {
        cursor: url(${dragCursor}) 30 30, auto;
    }

    ${media.desktopSmall`
        padding-top: 0;
    `}
    
    ${media.phone`
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        padding-top: 0;
    `}

    ${(props) => {
        if (props.hasInteracted)
            return css`
                * {
                    cursor: inherit;
                }
            `
    }}

    .swiper-container {
        ${padding}
        padding-left: calc((100vw - 1640px) / 2);

        @media (max-width: 1740px) {
            ${padding}
        }

        ${media.phone`
            width: 100vw;
        `}
    }

    .swiper-slide {
        width: 600px;
        height: auto;
        display: flex;
        flex-direction: column;

        ${media.tablet`
            width: 315px;
        `}
        
        ${media.phone`
            width: calc(100% - 40px - 18px);

            &:last-child {
                width: 100%;
            }
        `}

        &:hover,
        &:focus {
            ${BGImage} {
                transform: scale(1.05);
            }
        }
    }

    ${BGImage} {
        height: 350px;
        transform: scale(1.01); // prevents glitchyness on scale up
        transition: transform 400ms ease-in-out;

        ${media.tablet`
            height: auto;
            padding-bottom: 66%;
        `}

        ${media.phone`
            height: 175px;
            padding-bottom: 0;
        `}
    }

    /* Slide hover */

    .swiper-slide {
        display: flex;
        /* flex-direction: column; */
        /* align-items: flex-start; */

        &:hover {
            ${Subheading}::after {
                transform: none;
            }
        }
    }
`


// Hover Cursors

const Hover = styled.div`
	width: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 2;

	${props => {
		if (props.position == 'left') return css`
			left: 0;
			cursor: url(${require('../../assets/images/slider-hover-left.png')}), auto;	
		`

		if (props.position == 'right') return css`
			right: 0;
			cursor: url(${require('../../assets/images/slider-hover-right.png')}), auto;	

		`
	}}
`

const HoverCursors = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;

	${media.tablet`
		display: none;
	`}
`



export default NewsSlider
