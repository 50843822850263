import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { colours, container, bgIcon, padding, hoverState, linedText, textOffset, bgImage } from '../../../styles/global'

const BlockText = (props) => {
    const {
        pull_out_text,
        pull_out_text_position,
        short_text,
        sub_heading,
    } = props
    
	return (
        <TextBlock order={pull_out_text_position}>
            <LinedText>
                <span dangerouslySetInnerHTML={{ __html: pull_out_text }} />
            </LinedText>

            <div>
                <Subheading>{sub_heading}</Subheading>
                <ParagraphIndent
                    dangerouslySetInnerHTML={{
                        __html: short_text,
                    }}
                />
            </div>
        </TextBlock>
    )
}

const Subheading = styled.h2`
    color: #000;
    margin-bottom: 6px;
    font-size: 20px;
`

const ParagraphIndent = styled.div`
    ${textOffset};
    color: ${colours.midGray};
`

const TextBlock = styled.section`
    display: flex;
    align-items: flex-start;
    flex-direction: ${(props) => (props.order === 'left' ? 'row' : 'row-reverse')};
    
     margin-bottom: 110px;


    ${media.phone`
        /* margin-top: 40px; */
        margin-bottom: 60px;
        display: block;
    `}

    > div {
        flex: 1;
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }

        ${media.phone`
            margin-right: 0;
            margin-bottom: 40px;
        `}
    }
`


const LinedText = styled.div`
    ${linedText};
    color: ${colours.darkRed};
`

BlockText.wrapper = css`

`

export default BlockText
