import React, { useState, useEffect, createContext } from 'react';
import { useMount } from 'react-use'

export const headerContext = createContext();

const HeaderProvider = (props) => {
	const { Provider } = headerContext;
	const [logoTheme, setLogoTheme] = useState(false)
	const [hideByLine, setHideByLine] = useState(false)

	return (
		<Provider
			value={{
                logoTheme,
				setLogoTheme,
				hideByLine,
				setHideByLine
			}}
		>
            {props.children}
		</Provider>
	)
}

export default HeaderProvider
