import {
    BlockPulloutText,
    BlockDoubleText,
    BlockImage,
    Spacer,
    Slider,
} from './templates'

export const resolveBlock = (layout) => {
    // Map layout strings to blocks
    const blocks = {
        spacer: Spacer,

        double_text: BlockDoubleText,
        pullout_text: BlockPulloutText,

        single_image: BlockImage,
        double_portrait_images: BlockImage,
        landscape_images_x4: BlockImage,
        landscape_image_grid: BlockImage,
        landscape_portrait_images: BlockImage,
        image_slider: Slider,
    }
    
    return blocks[layout]
}
