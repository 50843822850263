import React from 'react'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import { PortalProvider } from "react-portal-hook";

import { Header, Splash } from './src/components'
import HeaderProvider from './src/components/Header/HeaderProvider';

export default ({ element, props  }) => {
    return (
        <>
            <GlobalStyles/>
            <HeaderProvider>
                <PortalProvider>
                    <Splash/>
                    <Header/>

                    {element}
                </PortalProvider>
            </HeaderProvider>
        </>
    )
}