import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`
        font-size: 100px;
        line-height: 1.2em;
    `,
    heading2: css`
        font-size: 40px;
        line-height: 1.625em;

        ${media.phone`
            font-size: 35px;
        `} 
    `,
    heading3: css`
        font-size: 35px;
        line-height: 1.42em;

        ${media.desktopSmall`
            font-size: 25px;
        `} 
    `,
    body: css`
        font-size: 20px;
        line-height: 30px;

        ${media.desktopSmall`
            font-size: 16px;
            line-height: 1.38em;
        `}
    `,
    caption: css`
        font-size: 16px;
        line-height: 1.625em;

        ${media.desktopSmall`
            font-size: 14px;
        `}
    `,
}

export default type
