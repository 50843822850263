import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';

import globalType from './type'
import globalColours from './colours';

const maxWidth = 1840;

export const GlobalStyles = createGlobalStyle`

    html {
        box-sizing: border-box;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        font-family: 'NeueHaas', sans-serif;
        font-weight: 300;
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p {
        ${globalType.body};
        letter-spacing: 0.15px;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        /* -webkit-overflow-scrolling: touch; */
        -webkit-font-smoothing: antialiased;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    p:first-child {
		margin-top: 0;
	}

    /* Next Wrapper */

    #gatsby-focus-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`


export const container = css`
	width: 100%;
    max-width: ${maxWidth}px;
    margin: 0 auto;
    /* prevent hoz scrolls */
    overflow-x: hidden;
`

export const pagePaddingTop = css`
    padding-top: 335px;

    ${media.phone`
        padding-top: 170px;
    `}
`

export const hoverState = css`
    cursor: pointer;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 100px; 
    padding-right: 100px;
    box-sizing: border-box;
    
    ${media.tablet`
        padding-left: 32px; 
        padding-right: 32px;
    `}

    ${media.phone`
        padding-left: 20px; 
        padding-right: 20px;
    `}
`

export const margin = css`
    margin-left: 50px; 
    margin-right: 50px;
    box-sizing: border-box;
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const textOffset = css`
    text-indent: 85px;

    ${media.phone`
        text-indent: 30px;
    `}
`

export const underlineAnimation = css`
    position: relative;
    transition: color 0.3s ease-in-out;
    will-change: color;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        left: 0;
        right: 0;
        transform: scaleX(0);
        transform-origin: left;
        background-color: ${globalColours.darkRed};
        transition: transform 0.3s ease-in-out;
        will-change: transform;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        left: 0;
        right: 0;
        background-color: transparent;
        transition: transform 0.3s ease-in-out;
        will-change: transform;
    }

    &:hover,
    &:focus {
        color: ${globalColours.darkRed};

        &::after {
            transform: none;
        }
    }
`

export const rolloverAnimation = css`
    position: relative;
    transition: color 0.3s ease-in-out;
    will-change: color;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        left: 0;
        right: 0;
        transform: scaleX(0);
        transform-origin: left;
        background-color: currentColor;
        transition: transform 0.3s ease-in-out;
        will-change: transform;
    }

    &:hover,
    &:focus {
        &::after {
            transform: none;
        }
    }
`

export const buttonStyles = css`
    color: ${globalColours.darkRed};
    font-size: 16px;
    /* margin-top: 40px; */
    padding-bottom: 8px;
    margin-right: 40px;
    display: inline-block;
    ${underlineAnimation};

    ${media.desktopSmall`
        margin-right: 25px;
        font-size: 12px;
        /* margin-top: 52px; */
        padding-bottom: 5px;
    `}
`

export const linedText = css`
	background: -webkit-linear-gradient(top, ${globalColours.red} 0%, transparent 4%) 0 50px;
	background-size: 100% 50px;
	padding-top: 1px;
	padding-bottom: 7px;

    > * {
        ${globalType.heading3}

      	/* Text offset override (larger) */

        padding-left: 140px;
        display: inline;

        ${media.tablet`
            padding-left: 85px;
            display: inline;
        `}
    }

	${media.desktopSmall`
		background: -webkit-linear-gradient(top, ${globalColours.red} 0%, transparent 4%) 0 36px;
		background-size: 100% 36px;
		padding-top: 2px;
		padding-bottom: 4px;
	`}

    ${media.phone`
        background-size: 100% 35px;
    `}
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType
