import React, { useState, useEffect, createContext } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'; 
import { useWindowSize, useMount } from 'react-use';
 
import { FULLPAGE_KEY } from '../../constants';
import { useBreakpoint, isClient } from '../../styles/utils';

const FullPage = (props) => {
	const { className, children } = props;
	const [API, setAPI] = useState(false)
	const { width, height } = useWindowSize();
	const isPhone = useBreakpoint('phone')
	const [mounted, setMounted] = useState(false)

	useMount(() => {
		if (isClient()) setMounted(true)
	})

	useEffect(() => {
		props.onApiChange?.(API)
	}, [API])

    useEffect(() => {
        API.reBuild?.()
	}, [width, height])
	
	if (!mounted) {
		return <></>
	}

	if (isPhone) {
		return children
	}
	
	// return children
	
	return (
		<ReactFullpage
			licenseKey={FULLPAGE_KEY}
			scrollingSpeed={1000}
			fitToSection={true}
			autoScrolling={true}
			onLeave={props.beforeSlideChange}
			render={(props) => {
				return (
					<FullPageWrapper
						setAPI={setAPI}
						{...props}
					>
						{children}
					</FullPageWrapper>
				)
			}}
		/>
	)
}

const FullPageWrapper = (props) => {
	const { children, state, fullpageApi } = props;

	useEffect(() => {
		props?.setAPI?.({
			...fullpageApi,
			...state
		})
	}, [state, fullpageApi])

	return (
		<ReactFullpage.Wrapper>
			{children}
		</ReactFullpage.Wrapper>
	)
}

export default FullPage
