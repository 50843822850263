import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Slider } from '../../../components'

import { media } from '../../../styles/utils'
import {
    bgImage,
    colours,
} from '../../../styles/global'

function SliderBlock(props) {

    const renderSlides = (slides) => {
        if (!slides || !slides.length) return;
        
        return slides.map((item, i) => {
            if (!item.image) return

            return (
                <Slide key={i}>
                    <Image image={item.image?.sizes?.medium2} />
                </Slide>
            )
        })
    }
    
	return (
        <Wrapper>
            <Slider
                slides={props.slider}
                renderSlides={renderSlides}
                sliderParams={{
                    effect: 'fade',
                    // speed: 750,
                    // autoplay: {
                    //     delay: 5000,
                    //     disableOnInteraction: false,
                    // },
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true,
                    },
                }}
                captions
                hoverCursors
            />
        </Wrapper>
    )
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

// Layout

const Wrapper = styled.section`
    width: 100%;
     margin-bottom: 110px;

    ${media.phone`
        margin-bottom: 60px;
    `}

    .swiper-pagination-bullets {
        width: auto !important;
        left: 40px !important;
        bottom: 45px !important;

        ${media.phone`
            position: static;
            text-align: left;
            margin-left: 12px;
            margin-top: 10px;
        `}
    }

    .swiper-pagination-bullet.swiper-pagination-bullet {
        margin: 0 15px !important;
        width: 10px;
        height: 10px;

        ${media.phone`
            margin: 0 10px !important;
            width: 7px;
            height: 7px;
        `}
    }

    .swiper-pagination-bullet {
        opacity: 0.4;
        background-color: #000;
    }

    .swiper-pagination-bullet-active {
        background-color: #d8d8da;
        opacity: 1;
    }

    ${media.phone`
        width: 100vw;
        transform: translateX(-20px);

        .caption {
            margin-top: 10px;
            margin-left: 20px;
            
            p {
                font-size: 16px;
            }
        }
    `}
`

const Image = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Slide = styled.div`
    padding-bottom: 57.89%;
    background-color: ${colours.lightGray};

    ${Image} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`


SliderBlock.wrapper = css`

`

export default SliderBlock
