import React, { useState, useEffect, useRef } from 'react'
import Swiper from 'react-id-swiper'
import ProgressiveImage from 'react-progressive-image'
import styled, { css } from 'styled-components'

import {
    container,
    padding,
    linedText,
    bgImage,
    colours,
    type,
    buttonStyles,
} from '../../styles/global'

import { media, useBreakpoint } from '../../styles/utils'
import { leftFillNum } from '../../utils/utils'

import ArrowRight from '../../assets/images/arrow-right.inline.svg'

const ToolsSlider = ({data}) => {
    const ref = useRef(null)
    const [activeIndex, updateActiveIndex] = useState(0)
    const [swiper, updateSwiper] = useState(null)
    const screenIsSmall = useBreakpoint('phone')
    const [hasInteracted, setHasInteracted] = useState(false)

    useEffect(() => {
        if (swiper) {
            swiper.on('slideChange', onSlideChange)
        }
    }, [swiper])

    const onSlideChange = () => {
        updateActiveIndex(swiper ? swiper.realIndex : 0)
    }

    const handleInteract = () => {
        setHasInteracted(true)
    }

    const {
        tools_intro,
        tools_link,
        tools_link_text,
        tools_use_link,
        tools_slider,
    } = data

    function renderIntro() {
        return (
            <aside>
                <MainHeading
                    as="h2"
                >
                    Tools
                </MainHeading>

                <LinedText>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: tools_intro,
                        }}
                    />
                </LinedText>

                {/* {tools_use_link && (
                    <ToolsLink href={tools_link}>{tools_link_text}</ToolsLink>
                )} */}
            </aside>
        )
    }

    function renderCards() {
        return (
            tools_slider.map(({ icon, title, short_text }, i) => (
                <div 
                    key={title + i}
                >
                    <Heading as="span">{leftFillNum(i + 1, 2)}</Heading>
                    <Image
                        key={icon?.sizes?.medium2}
                        src={icon?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{
                                        opacity: loading ? 0 : 1,
                                    }}
                                />
                            )
                        }}
                    </Image>
                    <h3>{title}</h3>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: short_text,
                        }}
                    />
                </div>
            ))
        )
    }

    function renderCounter() {
        return (
            <Counter>
                <ArrowRight />
                {leftFillNum(activeIndex + 1, 2)}
                <span>/{leftFillNum(tools_slider.length, 2)}</span>
            </Counter>
        )
    }

    const paramsSmall = {
        spaceBetween: 40,
        // slidesPerView: 1,
        getSwiper: updateSwiper,
        slidesPerView: 'auto',
    }
    
    const paramsLarge = {
        grabCursor: true,
        spaceBetween: 40,
        getSwiper: updateSwiper,
        freeMode: true,
        slidesPerView: 'auto',
    }

    const renderHoverCursors = () => {
		return (
			<HoverCursors className="hover-cursors">
				<Hover
					position={'left'}
					onClick={() => swiper && swiper.slidePrev()}
				>

				</Hover>
				<Hover
					position={'right'}
					onClick={() => swiper && swiper.slideNext()}
				>

				</Hover>
			</HoverCursors>
		)
    }

    return (
        <ToolsSection
            className={['section']}
            // data-interacted={hasInteracted}
            // onMouseDown={handleInteract}
            // onTouchStart={handleInteract}
        >
            {screenIsSmall ? (
                <>
                    {renderIntro()}
                    {renderCounter()}
                    <Swiper {...paramsSmall}>
                        {renderCards()}
                    </Swiper>
                </>
            ) : (
                <Swiper {...paramsLarge}>
                    {renderIntro()}
                    {renderCards()}
                </Swiper>
            )}

            {renderHoverCursors()}
        </ToolsSection>
    )
}

const Heading = styled.h2`
    ${type.heading2};
    color: ${colours.darkRed};
`

const MainHeading = styled.h2`
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
    height: 1080px;
`

const LinedText = styled.div`
    ${linedText}
`

const Counter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    ${padding}

    ${media.phone`
        margin-bottom: 20px;
    `}

    svg {
        margin-right: auto;
        width: 25px;
        height: 25px;

        ${media.phone`
            width: 18px;
            color: #D8D8DA;
        `}
    }

    span {
        color: ${colours.red};
    }
`

const ToolsSection = styled.section`
    background-color: ${colours.darkRed};
    color: #fff;
    width: 100%;
    align-items: center;
    display: flex;
    height: 100vh;
    max-height: 100vh;
    position: relative;
    /* padding-top: 150px;  */
    /* padding-bottom: 380px; */

    /* * {
        cursor: url(${require('../../assets/images/drag-cursor-white.png')}) 30 30, auto;
    } */

    &[data-interacted="true"] {
        * {
            cursor: inherit;
        }
    }

    ${media.phone`
        height: auto;
        max-height: none;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 50px;
        padding-bottom: 90px;
        overflow: hidden;
    `}

    ${Heading},
    ${MainHeading} {
        color: ${colours.red};
        font-size: 40px;
        margin-bottom: 90px;
        display: block;

        ${media.phone`
            margin-bottom: 40px;
        `}
    }

    ${MainHeading} {
        margin-bottom: 16px;
    }

    .swiper-slide {
        width: 380px;

        ${media.phone`
            width: 80%;
        `}

        &:first-child {
            width: 50%;

            ${media.phone`
                width: 80%;
            `}
        }
    }

    aside {
        max-width: calc((1640px / 2) - 170px);
        padding-right: 140px;

        ${media.phone`
            width: 100%;
            padding-right: 0;
            margin-bottom: 50px;
            ${padding}
        `}
    }

    h3 {
        color: ${colours.red};
        font-size: 20px;
        margin-bottom: 5px;

        ${media.desktopSmall`
            font-size: 16px;
        `}

        ${media.phone`
            font-size: 18px;
        `}
    }

    ${BGImage} {
        height: 225px;
        width: 225px;
        background-size: contain;
        background-position: center left;
        margin-bottom: 90px;

        ${media.phone`
            margin-bottom: 50px;
            height: 180px;
            width: 180px;
        `}
    }

    .swiper-container {
        ${padding}
        padding-left: calc((100vw - 1640px) / 2);

        @media (max-width: 1740px) {
            ${padding}
        }

        ${media.phone`
            width: 100vw;
        `}
    }
`

const ToolsLink = styled.a`
    ${buttonStyles};
    color: #e3b2ac;
    margin-top: 50px;
    padding-bottom: 4px;

    &::after {
        background-color: #e3b2ac;
    }

    &:hover {
        color: #e3b2ac;
    }
`

// Hover Cursors

const Hover = styled.div`
	width: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 2;

	${props => {
		if (props.position == 'left') return css`
			left: 0;
			cursor: url(${require('../../assets/images/slider-hover-left.png')}), auto;	
		`

		if (props.position == 'right') return css`
			right: 0;
			cursor: url(${require('../../assets/images/slider-hover-right.png')}), auto;	

		`
	}}
`

const HoverCursors = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;

	${media.tablet`
		display: none;
	`}
`



export default ToolsSlider

