import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, colours, bgIcon, padding, hoverState, bgImage, linedText } from '../../../styles/global'

const BlockPulloutText = (props) => {
    
	return (
        <LinedText>
            <span dangerouslySetInnerHTML={{ __html: props.text }} />
        </LinedText>
    )
}


const LinedText = styled.section`
    ${linedText};
    color: ${colours.darkRed};

     margin-bottom: 110px;

    ${media.phone`
        margin-bottom: 40px;
    `}
`


BlockPulloutText.wrapper = css`

`

export default BlockPulloutText
