import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'

function Spacer() {
	return <SpacerDiv />
}


const SpacerDiv = styled.div`
    width: 100%;
    height: 200px;

    ${media.phone`
        height: 100px;
    `}
`


Spacer.wrapper = css`

`

export default Spacer
