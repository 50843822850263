const colours = {
    darkRed: '#7B2609',
    red: '#C54644',
    lightGray: '#EBECEC',
    midGray: '#98999D',
    lightGray: '#D8D8DA',
    darkGray: '#595B60',
    offWhite: '#FFF4EF',
    offerWhite: '#FAEFEA',
}

export default colours
