import React from 'react'
import styled, { keyframes } from 'styled-components';
import { colours } from '../../styles/global'

const Splash = (props) => {
    return ( 
        <Wrapper/>
    )
}

const fadeOut = keyframes`
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
`

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colours.lightGray};
    z-index: 10;
    pointer-events: none;
    animation: ${fadeOut} 2s 3s ease-in-out forwards;
`

export default Splash
