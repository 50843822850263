import React, { useState, useEffect } from 'react'
// import { navigate, Link, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import Swiper from 'react-id-swiper'
import { useMount } from 'react-use';
import { padStart } from 'lodash'
// import { motion, AnimatePresence, transform } from 'framer-motion'

import { media } from '../../styles/utils'
import { container, bgImage, padding, hoverState, type, colours, bgIcon } from '../../styles/global'

const Slider = (props) => {
	const {slides, sliderStyles} = props;
	const [activeIndex, updateActiveIndex] = useState(0);
	const [swiper, updateSwiper] = useState(null);
	const canLoop = slides && slides.length > 1;
	
	const swiperParams = {
		getSwiper: updateSwiper,
		loop: canLoop,
		...props.sliderParams
	}

	useMount(() => {
		onSlideChange()
	})

	useEffect(() => {
		if (swiper) {
			swiper.on('slideChange', onSlideChange);
			props.onSliderMount && props.onSliderMount(swiper)
		}
	}, [swiper])

	useEffect(() => {
		if (!props.activeSlide || !swiper) return;
		swiper.slideTo(props.activeSlide, 1000)
	}, [props.activeSlide])

	useEffect(() => {
		props?.onSlideChange?.(activeIndex)
	}, [activeIndex])

	const onSlideChange = () => {
		updateActiveIndex(swiper ? (swiper.realIndex) : 0)
	}

	const renderSlide = (item, i) => {
		const image = item.image;

		return (
			<Slide>
				<BGImage 
					image={image} 
				/>
			</Slide>
		)
	}

	const renderSlides = () => {
		if (!slides || !slides.length) return;

		return slides.map((item, i) => {
			return renderSlide(item, i)
		})
	}

	const renderCounter = () => {
		return (
			<Counter className="counter">
                <Subheading>
                    {padStart(activeIndex + 1, 2, '0')}
                </Subheading>
                
                <Subheading>
                    <span>
                        /{padStart(slides && slides.length, 2, '0')}
                    </span>
                </Subheading>

                {/* <Arrow	
                    onClick={() => swiper && swiper.slideNext()}
                /> */}
			</Counter>
		)
	}

	const renderHoverCursors = () => {
		return (
			<HoverCursors className="hover-cursors">
				<Hover
					position={'left'}
					onClick={() => swiper && swiper.slidePrev()}
				>

				</Hover>
				<Hover
					position={'right'}
					onClick={() => swiper && swiper.slideNext()}
				>

				</Hover>
			</HoverCursors>
		)
    }
    
    function renderCaptions() {
        if (!slides[activeIndex]?.use_caption || !slides[activeIndex]?.caption) return null

        return (
            <Caption
				className={'caption'}
                dangerouslySetInnerHTML={{
                    __html: slides[activeIndex].caption,
                }}
            />
        )
    }
	
	return (
		<Wrapper
			sliderStyles={sliderStyles}
		>
			<Swiper
				{...swiperParams}
			>
				{props.renderSlides && props.renderSlides(slides) || renderSlides()}
			</Swiper>

            {props.captions && renderCaptions()}

			<Container className="counter-wrapper">
				{props.counter && renderCounter()}
			</Container>

			{props.hoverCursors && renderHoverCursors()}
		</Wrapper>
	)
}


// Shared


const Description = styled.div``
const Subheading = styled.div``
const Icon = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Arrow = styled.div``

// Layout

const defaultSliderStyles = css`

`

const Wrapper = styled.div`
	position: relative;

	.swiper-container {
		${props => props.sliderStyles || defaultSliderStyles}
	}
`

const Container = styled.div`
	${container}
	${padding}
	margin: 0 auto;
	position: relative;
`


// Slide

const Slide = styled.div`
	display: flex;

	${BGImage} {
		height: 100%;
		padding-bottom: 80%;
		flex: 1;
	}
`

// Counter

const Counter = styled.div`
	position: absolute;
	bottom: 42px;
	right: 50px;
	z-index: 2;
	display: flex;
	align-items: center;

	${Subheading} {
		${type.heading3}
		color: white;

		span { 
			color: ${colours.red}
		}

		${media.tablet`
			color: ${colours.darkGray};

			span {
				color: ${colours.darkRed}
			}
		`}

		&:first-child {
			visibility: hidden;
		}
	}

	${Arrow} {
		background-image: url(${require('../../assets/images/arrow-right.svg')});
		width: 19px;
		height: 16px;
		margin-left: 8px;
		${bgIcon}
        ${hoverState}
        display: none;
        
        ${media.tablet`
            display: block;
        `}
	}

	${media.tablet`
		bottom: 20px;
		right: 20px;

		${Subheading} {
			font-size: 18px;
		}

		${Arrow} {
			display: none;
		}
	`}

	${media.phone`
		bottom: 0;
		height: 80px;
	`}
`

const Caption = styled.div`
    position: absolute;
    bottom: 45px;
    right: 40px;
    z-index: 1;
    color: ${colours.darkGray};

    p {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
    }

    ${media.phone`
        position: static;
        margin-top: 2px;

        p {
            font-size: 14px;
        }
    `}
`

// Hover Cursors

const Hover = styled.div`
	width: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 2;

	${props => {
		if (props.position == 'left') return css`
			left: 0;
			cursor: url(${require('../../assets/images/slider-hover-left.png')}), auto;	
		`

		if (props.position == 'right') return css`
			right: 0;
			cursor: url(${require('../../assets/images/slider-hover-right.png')}), auto;	

		`
	}}
`

const HoverCursors = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;

	${media.tablet`
		display: none;
	`}
`

export default Slider
