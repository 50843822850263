import React from 'react'
import styled from 'styled-components'

import { container, padding, colours, type, underlineAnimation } from '../../styles/global'

import logoSmall from '../../assets/images/logo-small-22.svg'
import InstagramLogo from '../../assets/images/instagram.inline.svg'

import { getGlobalData } from '../../utils/utils'
import { media } from '../../styles/utils'

const FooterComponent = (props) => {
    const { data } = getGlobalData('footer')
    const { className } = props

    const {
        call,
        email,
        copyright,
        display_instagram_link,
        visit_link,
        visit_link_text,
    } = data

    return (
        <Footer
            className={className}
        >
            <FooterRow>
                <TopContainer>
                    <FooterColumn>
                        <Logo src={logoSmall} alt="Platform logo" />
                    </FooterColumn>
                    <FooterColumn>
                        <Underline>
                            <FooterAnchor href={`mailto:${email}`}>Email – {email}</FooterAnchor>
                        </Underline>

                    </FooterColumn>
                    <FooterColumn>
                        <Underline>
                            <FooterAnchor href={`tel:${call}`}>Call – {call}</FooterAnchor>
                        </Underline>

                    </FooterColumn>
                    {display_instagram_link && (
                        <IconLink 
                            href={data.instagram_link}
                            target="_blank" 
                            title="Instagram"
                        >
                            <InstagramLogo />
                        </IconLink>
                    )}
                </TopContainer>

            </FooterRow>

            <SubFooterRow>

                <BottomContainer>
                    <FooterColumn>
                        <span>{copyright}</span>
                    </FooterColumn>
                    <FooterColumn>
                        <Underline
                            className={'visit-link'}
                        >
                            <SubFooterAnchor href={visit_link}>
                                {visit_link_text}
                            </SubFooterAnchor>
                        </Underline>
                    </FooterColumn>
                </BottomContainer>

            </SubFooterRow>

        </Footer>
    )
}

const Container = styled.div`
    ${container}
	${padding}
    display: flex;
    flex-direction: row;
    margin: 0 auto;
`

const Footer = styled.footer`
    width: 100%;
`

const TopContainer = styled(Container)`
    padding-top: 55px;
    padding-bottom: 240px;

    ${media.phone`
        padding-top: 180px;
        padding-bottom: 60px;
        flex-direction: column;
    `}
`

const FooterColumn = styled.div`
    flex: 1;

    &:first-child {
        flex: inherit;
        width: 50%;

        ${media.tablet`
            width: 33%;
        `}
        
        ${media.phone`
            width: inherit;
        `}
    }

    ${media.tablet`
        .visit-link {
            &::after,
            &::before  {
                display: none;
            }
        }
    `}
`

const BottomContainer = styled(Container)`
    padding-top: 20px;
    padding-bottom: 20px;

    ${media.phone`
        flex-direction: column-reverse;
        padding-top: 0;
        padding-bottom: 0;

        ${FooterColumn} {
            padding-top: 10px;
            padding-bottom: 10px;

            &:last-child {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    bottom: 0;
                    left: -20px;
                    right: -20px;
                    background-color: #F1D8D5;
                }
            }
        }
    `}

    
`

const FooterRow = styled.div`
    background-color: ${colours.offWhite};
`

const SubFooterRow = styled.div`
    font-size: 16px;
    color: ${colours.darkRed};
    background-color: ${colours.offerWhite};

    ${media.desktopSmall`
        font-size: 14px;
    `}

    ${media.phone`
        font-size: 15px;
    `}
`

const SubFooterAnchor = styled.a`
    font-size: inherit;
    color: inherit;
    line-height: inherit;
`

const Underline = styled.span`
    padding-bottom: 6px;
    position: relative;
    color: ${colours.darkRed};
    ${underlineAnimation};

    ${media.desktopSmall`
        font-size: 14px;
    `}

    &::after {
        background-color: ${colours.darkRed};
    }

    &::before {
        background-color: transparent;
    }

    &:hover,
    &:focus {
        &::after {
            transform: none;
        }
    }
`

const FooterAnchor = styled.a`
    color: ${colours.darkRed};
    font-size: 16px;

    ${media.desktopSmall`
        font-size: 14px;
    `}

    ${media.phone`
        font-size: 15px;
        margin-bottom: 10px;
        display: inline-block;
    `}
    
`

const Logo = styled.img`
    width: 150px;
    flex: inherit;

    ${media.phone`
        margin-bottom: 25px;
        width: 100px;
    `}
`

const IconLink = styled.a`
    color: ${colours.darkRed};
    width: 20px;
    display: block;

    ${media.phone`
        margin-top: 15px;
    `}
`

export default FooterComponent
